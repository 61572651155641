export default {
  // Component Data
  async data() {
    var self = this;
    var data = JSON.parse(localStorage.getItem('token'));
    return {
      id: self.$route.params.id,
      apiKey: data.apiKey
    };
  },

  // Component Methods
  methods: {
    back: function () {
      var self = this;
      self.$app.views.main.router.navigate("/client/" + self.$route.params.id);
    },
    save: function () {
      var self = this;
      self.$app.preloader.show();
      var form = self.$app.form.convertToData("#client-form");
      var formData = new FormData();
      formData.append("description", form.description);
      formData.append("profilepic", form.profilepic);
      formData.append("clientid", form.clientid);
      var files = document.getElementById('file').files;

      for (var i in files) {
        formData.append("files" + i, files[i]);
      }

      self.$app.request({
        url: 'https://api.youreontime.com/1/api/photo/save',
        data: formData,
        contentType: 'multipart/form-data',
        method: 'POST',
        dataType: 'json',
        crossDomain: true,
        complete: function (xhr, status) {//console.log(xhr, status);
        },
        success: function (data) {
          self.$app.preloader.hide();

          if (!data.complete) {
            self.$app.notification.create({
              text: data.error,
              closeTimeout: 4000
            }).open();
            return;
          }

          self.$app.views.main.router.navigate("/client/" + form.clientid);
        },
        error: function (xhr, status, message) {
          self.$app.preloader.hide();
          self.$app.notification.create({
            text: "An error has occurred",
            closeTimeout: 4000
          }).open();
        }
      });
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
      var data = JSON.parse(localStorage.getItem('token'));
      self.$app.request.setup({
        headers: {
          'APIKey': data.apiKey
        }
      });
    }
  },
  id: 'defe0f9fa0',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center">Client Photo</div></div></div><!-- Scrollable page content--><div class=page-content><form class=list method=POST id=client-form action=https://api.youreontime.com/1/api/photo/save enctype=multipart/form-data><input type=hidden name=clientid value=';
      r += c(ctx_1.id, ctx_1);
      r += '> <input type=hidden name=APIKey value=';
      r += c(ctx_1.apiKey, ctx_1);
      r += '><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Description</div><div class=item-input-wrap><textarea rows=3 name=description placeholder="Enter a description of this photo (optional)"></textarea></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Select Photo</div><div class=item-input-wrap><input type=file id=file name=file multiple=multiple accept="image/*;capture=camera"></div></div></div></li><li><div class=item-content><div class=item-inner><div class=item-title>Use this photo as the client profile picture</div><div class=item-after><label class="toggle toggle-init"><input type=checkbox name=profilepic> <span class=toggle-icon></span></label></div></div></div></li></ul><div class="block block-strong row"><div class=col><button class="button button-raised button-fill save" type=button @click=save>Save</button></div><div class=col><a class="button button-raised button-outline back" @click=back href=#>Cancel</a></div></div></form></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Component Data
  async data() {
    var self = this;
    var token = JSON.parse(localStorage.getItem('token'));
    self.$app.request.setup({
      headers: {
        'APIKey': token.apiKey
      }
    });
    self.$app.preloader.show();
    var id = self.$route.params.id;
    var data = await self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/client/' + id, {
      locationId: token.locationId
    }).then(res => {
      self.$app.preloader.hide();
      return res.data;
    });
    data.security = token.security;
    return data;
  },

  // Component Methods
  methods: {
    back: function () {
      var self = this;
      self.$app.views.main.router.navigate("/clients"); //self.$app.views.main.router.back('/', {force: true, ignoreCache: true, reload: true, reloadCurrent: true})
    },
    edit: function () {
      var self = this;
      self.$app.views.main.router.navigate("/client/edit/" + self.$route.params.id);
    },
    photos: function () {
      var self = this;
      self.$app.views.main.router.navigate("/client/photos/" + self.$route.params.id);
    },
    notes: function () {
      var self = this;
      self.$app.views.main.router.navigate("/client/notes/" + self.$route.params.id);
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this; //self.$$('.client-photos').on('click', function () {
      //  self.$app.views.main.router.navigate("/client/photo/add/" + self.$route.params.id);
      //});      
    }
  },
  id: '648bb4a3cd',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center">Client Profile</div><div class=right><a href=# class=link @click=back><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div></div></div><!-- Scrollable page content--><div class=page-content><div class=client-avatar-container><div class=client-avatar>';
      r += c(ctx_1.initial, ctx_1);
      r += ' <img src=https://app.youreontime.com/1/api/client/photo/';
      r += c(ctx_1.privateId, ctx_1);
      r += ' width=44></div></div><div class="block-title block-header">';
      r += c(ctx_1.name, ctx_1);
      r += '</div><div class=list><ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.phone, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=tel:';
          r += c(ctx_2.phoneNumber, ctx_2);
          r += ' class="item-link item-content external"><div class=item-inner><div class=item-title><div class=item-header>Phone</div>';
          r += c(ctx_2.phone, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.emailAddress, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=/client/edit/';
          r += c(ctx_2.id, ctx_2);
          r += ' class="item-link item-content"><div class=item-inner><div class=item-title><div class=item-header>Email</div>';
          r += c(ctx_2.emailAddress, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><div class=block-title>Appointments</div><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.appointments, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li>';
          r += Template7Helpers.if.call(ctx_2, ctx_1.security.canEditAppointment, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=/appointment/edit/';
              r += c(ctx_3.id, ctx_3);
              r += ' class="item-link item-content"><div class=item-media>';
              r += c(ctx_3.day, ctx_3);
              r += '<br>';
              r += c(ctx_3.month, ctx_3);
              r += '</div><div class=item-inner><div class=item-header>';
              r += c(ctx_3.dayOfWeek, ctx_3);
              r += ' ';
              r += c(ctx_3.startTime, ctx_3);
              r += '</div><div class=item-title>';
              r += c(ctx_3.description, ctx_3);
              r += '</div><div class=item-footer>';
              r += c(ctx_3.length, ctx_3);
              r += '</div></div></a>';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <a class="item-link item-content"><div class=item-media>';
              r += c(ctx_3.day, ctx_3);
              r += '<br>';
              r += c(ctx_3.month, ctx_3);
              r += '</div><div class=item-inner><div class=item-header>';
              r += c(ctx_3.dayOfWeek, ctx_3);
              r += ' ';
              r += c(ctx_3.startTime, ctx_3);
              r += '</div><div class=item-title>';
              r += c(ctx_3.description, ctx_3);
              r += '</div><div class=item-footer>';
              r += c(ctx_3.length, ctx_3);
              r += '</div></div></a>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</li>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-content><div class=item-inner style=text-align:center><img width=100px src=/static/icons/emptylist.svg><div class=item-title>No current appointments</div></div></div></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div class="toolbar tabbar-labels toolbar-bottom"><div class=toolbar-inner><a href=#tab-2 class=tab-link @click=edit><i class="icon material-icons">mode_edit</i> <span class=tabbar-label>Edit</span> </a><a href=#tab-2 class=tab-link @click=notes><i class="icon material-icons">description';
      r += Template7Helpers.if.call(ctx_1, ctx_1.noteCount, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="badge color-red">';
          r += c(ctx_2.noteCount, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</i> <span class=tabbar-label>Notes</span> </a><a href=#tab-2 class=tab-link @click=photos><i class="icon material-icons">collections';
      r += Template7Helpers.if.call(ctx_1, ctx_1.photoCount, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<span class="badge color-red">';
          r += c(ctx_2.photoCount, ctx_2);
          r += '</span>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</i> <span class=tabbar-label>Photos</span></a></div></div></div>';
      return r;
    }(this);
  },

  style: `

  .client-avatar {
    background-color: #cfebea;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center; 
    font-size: 32px;   
    margin: auto;
    margin-top: 20px;
    position:relative;
  }

  .client-avatar img {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .client-avatar-container {
    text-align: center;
  }

  .block-header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }

`,
  styleScoped: false
};
    
export default {
  // Component Data
  async data() {
    var self = this;
    return {
      id: 0
    };
  },

  // Component Methods
  methods: {
    back: function () {
      var self = this;
      self.$app.views.main.router.navigate("/appointment/back/" + self.$app.data.currentAppointment.id);
    },
    save: function () {
      var self = this;
      var formData = self.$app.form.convertToData("#client-form");
      var data = JSON.parse(localStorage.getItem('token'));
      formData.locationId = data.locationId;
      formData.staffId = data.staffId;
      self.$app.preloader.show();
      self.$app.request.postJSON('https://api.youreontime.com/1/api/client/save', formData, function (data) {
        self.$app.preloader.hide();

        if (!data.complete) {
          self.$app.dialog.alert(data.error);
          return;
        }

        self.$app.data.currentAppointment.clientEmail = data.emailAddress;
        self.$app.data.currentAppointment.clientId = data.id;
        self.$app.data.currentAppointment.clientName = data.name;
        self.$app.data.currentAppointment.clientPhone = data.mobilePhone;
        self.$app.views.main.router.navigate("/appointment/back/" + self.$app.data.currentAppointment.id);
      });
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '96e7faff6c',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center">Add Client</div><div class=right><a href=# class=link @click=back><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div></div></div><!-- Scrollable page content--><div class=page-content><form class=list id=client-form><input type=hidden name=id value=';
      r += c(ctx_1.id, ctx_1);
      r += '><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">First Name</div><div class=item-input-wrap><input type=text name=firstName placeholder="First Name"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Last Name</div><div class=item-input-wrap><input type=text name=lastName placeholder="Last Name"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Mobile Phone</div><div class=item-input-wrap><input type=tel name=mobilePhone placeholder="Mobile Phone"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Home Phone</div><div class=item-input-wrap><input type=tel name=homePhone placeholder="Home Phone"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">E-mail</div><div class=item-input-wrap><input type=email name=email placeholder=E-mail></div></div></div></li></ul></form><div class="block block-strong row"><div class=col><a class="button button-raised button-fill save" @click=save href=#>Save</a></div><div class=col><a class="button button-raised button-outline" @click=back href=#>Cancel</a></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      async function doSearch(self, searchText) {
  var data = JSON.parse(localStorage.getItem('token'));
  self.$app.request.setup({
    headers: {
      'APIKey': data.apiKey,
      'StaffId': data.staffId
    }
  });
  self.$app.preloader.show();
  var data = await self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/clients', {
    locationId: data.locationId,
    search: searchText
  }).then(res => {
    self.$app.preloader.hide();
    return res.data;
  });
  return data;
}

function doSearch2(self, searchText, cb) {
  var data = JSON.parse(localStorage.getItem('token'));
  self.$app.request.setup({
    headers: {
      'APIKey': data.apiKey,
      'StaffId': data.staffId
    }
  });
  self.$app.preloader.show();
  var data = self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/clients', {
    locationId: data.locationId,
    search: searchText
  }).then(res => {
    self.$app.preloader.hide();
    cb(res.data);
  });
  return data;
}

export default {
  // Component Data
  async data() {
    var token = JSON.parse(localStorage.getItem('token'));
    var self = this;
    var data = await doSearch(self);
    return {
      items: data,
      canAccessClientContacts: token.security.canAccessClientContacts,
      canAccessClient: token.security.canAccessClient
    };
  },

  // Component Methods
  methods: {
    addClient: function () {
      var self = this; //self.$app.dialog.alert("Add Client");

      self.$app.views.main.router.navigate("/clientadd");
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
      var searchTimer;
      var searchbar = self.$app.searchbar.create({
        el: '.searchbar',
        customSearch: true,
        on: {
          async search(sb, query, previousQuery) {
            if (searchTimer) clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
              doSearch2(self, query, data => {
                self.$setState({
                  items: data
                });
              });
            }, 1000);
          }

        }
      });
    }
  },
  id: 'e4021ba508',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n    <!-- Top Navbar -->\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n        <div class="left">\n          <a href="#" class="link icon-only panel-open" data-panel="left">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n        </div>\n        <div class="title center">Clients</div>\n        <div class="subnavbar">\n          <!-- Searchbar with auto init -->\n          <form class="searchbar">\n            <div class="searchbar-inner">\n              <div class="searchbar-input-wrap">\n                <input type="search" placeholder="Search by name or phone number">\n                <i class="searchbar-icon"></i>\n                <span class="input-clear-button"></span>\n              </div>\n              <span class="searchbar-disable-button if-not-aurora">Cancel</span>\n            </div>\n          </form>\n        </div>        \n      </div>\n    </div>\n    <!-- Floating Action Button -->\n    <div class="fab fab-right-bottom">\n      <a href="#" @click="addClient">\n        <i class="icon f7-icons">plus</i>\n      </a>\n    </div>    \n    <!-- Scrollable page content-->\n    <div class="page-content">\n\n      <!-- Searchbar backdrop -->\n      <div class="searchbar-backdrop"></div>\n      \n      <!-- search target list -->\n      <div class="list media-list">\n        <ul class="">\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.items, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n          <li>\n            <a ';
          r += Template7Helpers.if.call(ctx_2, ctx_1.canAccessClient, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' href="/client/';
              r += c(ctx_3.id, ctx_3);
              r += '" ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' class="item-link item-content">\n              <div class="item-media">\n                <div class="profile-image">\n                  ';
          r += c(ctx_2.initial, ctx_2);
          r += '\n                  <img src="https://api.youreontime.com/1/api/client/photo/';
          r += c(ctx_2.privateId, ctx_2);
          r += '" width="44"/>\n                </div>\n              </div>\n              <div class="item-inner">\n                <div class="item-title-row">\n                  <div class="item-title">';
          r += c(ctx_2.name, ctx_2);
          r += '</div>\n                </div>\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_1.canAccessClientContacts, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                <div class="item-subtitle client-details">';
              r += c(ctx_3.phone, ctx_3);
              r += ' ';
              r += c(ctx_3.emailAddress, ctx_3);
              r += '</div>\n                ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n              </div>\n            </a>\n          </li>\n          ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '          \n        </ul>\n      </div>\n\n\n      <!-- Nothing found message -->\n      <div class="block searchbar-not-found">\n        <div class="block-inner">Nothing found</div>\n      </div>\n\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  style: `
  .profile-image {
    background-color: #cfebea;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;    
  }

  .profile-image img {
    position: absolute;
    top: 15px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
  }

`,
  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Component Data
  async data() {
    var self = this;
    var data = JSON.parse(localStorage.getItem('token'));
    self.$app.request.setup({
      headers: {
        'APIKey': data.apiKey,
        'StaffId': data.staffId
      }
    });
    self.$app.preloader.show();
    var id = self.$route.params.id;
    var data = await self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/clientnotes/' + id, {
      locationId: data.locationId
    }).then(res => {
      self.$app.preloader.hide();
      return res.data;
    });
    return data;
  },

  // Component Methods
  methods: {
    back: function () {
      var self = this;
      self.$app.views.main.router.navigate("/client/" + self.$route.params.id);
    },
    add: function () {
      var self = this;
      self.$app.views.main.router.navigate("/client/note/add/" + self.$route.params.id);
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: 'a332605c67',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center">';
      r += c(ctx_1.name, ctx_1);
      r += ' Notes</div><div class=right><a href=# class=link @click=back><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div></div></div><!-- Scrollable page content--><div class=page-content><div class="list media-list"><ul>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.notes, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-content><div class=item-inner><div class=item-title>';
          r += c(ctx_2.note, ctx_2);
          r += '</div><div class=item-footer>';
          r += c(ctx_2.dateAdded, ctx_2);
          r += '</div></div></div></li>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div class=item-content><div class=item-inner style=text-align:center><img width=50% src=/static/icons/emptynotes.svg><div class=item-title>No current notes</div></div></div></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><!-- Floating Action Button --><div class="fab fab-right-bottom"><a @click=add><i class="icon f7-icons">plus</i></a></div></div>';
      return r;
    }(this);
  },

  style: `

  .client-avatar {
    background-color: #cfebea;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center; 
    font-size: 32px;   
    margin: auto;
    margin-top: 20px;
    position:relative;
  }

  .client-avatar img {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .client-avatar-container {
    text-align: center;
  }

  .block-header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 10px;
  }

`,
  styleScoped: false
};
    
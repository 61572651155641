export default {
  // Component Data
  async data() {
    var self = this;
    var data = JSON.parse(localStorage.getItem('token'));
    self.$app.request.setup({
      headers: {
        'APIKey': data.apiKey
      }
    });
    self.$app.preloader.show();
    var id = 1; //console.log('Hello');

    var data = await self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/client/', {
      locationId: data.locationId
    }).then(res => {
      self.$app.preloader.hide();
      return res.data;
    });
    return data;
  },

  // Component Methods
  methods: {
    edit: function () {
      var self = this;
      self.$app.dialog.alert('Hello World');
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: 'd8cc79fc6a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center">Client Profile</div></div></div><!-- Scrollable page content--><div class=page-content>Details</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
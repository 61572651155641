
import HomePage from '../pages/home.f7.html';

import AppointmentPage from '../pages/appointment.f7.html';
import AppointmentDatePage from '../pages/appointmentdate.f7.html';
import AppointmentEditPage from '../pages/appointmentedit.f7.html';
import AppointmentEditClientsPage from '../pages/appointmentclients.f7.html';
import AppointmentEditClientAddPage from '../pages/appointmentclientedit.f7.html';
import AppointmentServicePage from '../pages/serviceedit.f7.html';
import AppointmentBreakAddPage from '../pages/breakadd.f7.html';

import ClientSearchPage from '../pages/clients.f7.html';
import ClientPage from '../pages/clientprofile.f7.html';
import ClientEditPage from '../pages/clientedit.f7.html';
import ClientAddPage from '../pages/clientadd.f7.html';
import ClientEditPersonalPage from '../pages/clientpersonal.f7.html';
import ClientEditAddressPage from '../pages/clientaddress.f7.html';
import ClientNoteListPage from '../pages/clientnotes.f7.html';
import ClientNotePage from '../pages/clientnote.f7.html';
import ClientPhotoListPage from '../pages/clientphotos.f7.html';
import ClientPhotoPage from '../pages/clientphoto.f7.html';
//todo: change location, top right menu

import AboutPage from '../pages/about.f7.html';
import FormPage from '../pages/form.f7.html';
import LoginPage from '../pages/login.f7.html';

import LeftPage1 from '../pages/left-page-1.f7.html';
import LeftPage2 from '../pages/left-page-2.f7.html';
import DynamicRoutePage from '../pages/dynamic-route.f7.html';
import RequestAndLoad from '../pages/request-and-load.f7.html';
import NotFoundPage from '../pages/404.f7.html';

function checkAuth(to, from, resolve, reject) {
  var router = this;
  if (!localStorage.getItem("token")) {
    reject();
    router.navigate('/login');
  } else {   
    var data = JSON.parse(localStorage.getItem("token"));
    if (!data.security.canAccessMobile) {
      reject();
      router.navigate('/login');
      return;
    }
    resolve();
  }
}

function setPermissions(e, page) {
  var data = JSON.parse(localStorage.getItem("token"));
  //alert(JSON.stringify(data));
  //console.log(data);

  if (!data || !data.security) return;

  page.app.$('.change-location').removeClass('menu-visible');
  page.app.$('.view-clients').removeClass('menu-visible');
  page.app.$('.view-appointments').removeClass('menu-visible');

  if (data.security.canChangeLocation) {
    page.app.$('.change-location').addClass('menu-visible');
  }  
  if (data.security.canAccessClient) {
    page.app.$('.view-clients').addClass('menu-visible');
  }  
  if (data.security.canAccessAppointment) {
    page.app.$('.view-appointments').addClass('menu-visible');
  } 
   

  page.app.$('.staff-menu-container').show();
  if (!data.security.canChangeStaff) {
    page.app.$('.staff-menu-container').hide();
  }

  //alert('setPermissions');
}

var routes = [
  {
    path: '/',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },  
    component: HomePage
  },
  {
    path: '/dashboard',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: HomePage,
  },  

  {
    path: '/staff/change/:id',
    async: function (routeTo, routeFrom, resolve, reject) {

      var router = this;
      var app = router.app;

      var id = routeTo.params.id;

      var token = JSON.parse(localStorage.getItem('token'));

      var staff = app.data.allStaff.filter(x => x.id == id)[0];

      token.staffId = parseInt(staff.id);
      token.staffName = staff.name;
      localStorage.setItem('token', JSON.stringify(token));

      app.views.main.router.navigate("/appointments/", {reloadCurrent: true});
    },
  },

  {
    path: '/appointments',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentPage,
  },
  {
    path: '/appointmentdate',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentDatePage,
  },
  {
    path: '/appointment/back/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentEditPage,
  },  
  {
    path: '/appointment/edit/:id',
    //beforeEnter: checkAuth,
    //component: AppointmentEditPage,
    on: {
      pageAfterIn: setPermissions
    },
    async: function (routeTo, routeFrom, resolve, reject) {

      var router = this;
      var app = router.app;

      app.preloader.show();

      var id = routeTo.params.id;

      var data = JSON.parse(localStorage.getItem('token'));

      app.request.setup({
        headers: {
          'APIKey': data.apiKey
        }
      }); 

      var selectedDate = null;
      if (app.data.selectedDate) {
        selectedDate = moment(app.data.selectedDate).format("YYYY-MM-DD[T00:00:00]");
      } 
      
      if (routeTo.query.date) {
        selectedDate = moment(new Date(parseInt(routeTo.query.date))).format("YYYY-MM-DDTHH:mm:ss");
      }

      var data = app.request.promise.getJSON('https://api.youreontime.com/1/api/appointment/' + id, { locationId: data.locationId, selectedDate: selectedDate, staffId: routeTo.query.staffid })
      .then(res => {
        app.preloader.hide();
        app.data.currentAppointment = res.data;
        if (routeTo.query.staffid) {
          app.data.currentAppointment.staffId = routeTo.query.staffid;
        }
  
        resolve(
          {
            component: AppointmentEditPage,
          }
        );
      });
    },
  },
  {
    path: '/appointment/serviceedit/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentServicePage,
  },
  {
    path: '/appointment/clients',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentEditClientsPage,
  },  
  {
    path: '/appointment/clients',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentEditClientsPage,
  },  
  {
    path: '/appointment/clientadd',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentEditClientAddPage,
  },  
  {
    path: '/break/edit/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: AppointmentBreakAddPage,
  },  
  {
    path: '/clients',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientSearchPage,
  },
  {
    path: '/client/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientPage,
  },
  {
    path: '/clientadd',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientAddPage,
  },
  {
    path: '/client/edit/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientEditPage,
  },
  {
    path: '/client/edit/address/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientEditAddressPage, // Look at master/detail routes for this
  },
  {
    path: '/client/edit/personal/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientEditPersonalPage,
  },
  {
    path: '/client/notes/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientNoteListPage,
  },
  {
    path: '/client/note/add/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientNotePage,
  },

  {
    path: '/client/photos/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientPhotoListPage,
  },
  {
    path: '/client/photo/add/:id',
    beforeEnter: checkAuth,
    on: {
      pageAfterIn: setPermissions
    },
    component: ClientPhotoPage,
  },


  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/left-page-1/',
    component: LeftPage1,
  },
  {
    path: '/left-page-2/',
    component: LeftPage2,
  },
  {
    path: '/login',
    component: LoginPage,
    on: {
      beforeAfterIn: function(){
        app.navbar.hide('.navbar');
      }
    }    
  },
  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
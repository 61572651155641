
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Component Data
  async data() {
    var self = this;
    var data = JSON.parse(localStorage.getItem('token'));
    console.log('break');
    self.$app.request.setup({
      headers: {
        'APIKey': data.apiKey,
        'StaffId': data.staffId
      }
    });
    self.$app.preloader.show();
    var id = self.$route.params.id;
    var selectedDate = moment(new Date(parseInt(self.$route.query.date))).format("YYYY-MM-DDTHH:mm:ss");
    var staffid = self.$route.query.staffid;
    if (!staffid || staffid == 'undefined') staffid = data.staffId;
    console.log(staffid);
    var data = await self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/break/' + id, {
      locationId: data.locationId,
      staffId: staffid,
      selectedDate: selectedDate
    }).then(res => {
      self.$app.preloader.hide();
      return res.data;
    });
    data.times = getTimes(data.interval, data.breakDate + 'T' + data.startTime);
    data.lengths = getLengths(data.interval, data.interval);
    console.log(data);
    return data;
  },

  // Component Methods
  methods: {
    back: function () {
      var self = this;
      console.log(self.$route.query);
      self.$app.views.main.router.navigate("/appointments");
    },
    save: function () {
      var self = this;
      var formData = self.$app.form.convertToData("#break-form");
      console.log(self.$route.query);
      var data = JSON.parse(localStorage.getItem('token'));
      self.$app.preloader.show();
      self.$app.request.postJSON('https://api.youreontime.com/1/api/break/save', formData, function (data) {
        self.$app.preloader.hide();

        if (!data.complete) {
          self.$app.notification.create({
            text: data.error,
            closeTimeout: 4000
          }).open();
          return;
        }

        self.$app.views.main.router.navigate("/appointments");
      });
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '6698550c59',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n    <!-- Top Navbar -->\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n        <div class="left">\n          <a href="#" class="link icon-only panel-open" data-panel="left">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n        </div>        \n        <div class="title center">Add Break</div>\n        <div class="right">\n          <a href="#" class="link" @click="back">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md">Back</span>\n          </a>\n        </div>\n      </div>\n    </div>\n    <!-- Scrollable page content-->\n    <div class="page-content">\n\n      <form class="list" id="break-form">\n\n        <input type="hidden" name="id" value="';
      r += c(ctx_1.id, ctx_1);
      r += '" />\n        <input type="hidden" name="locationId" value="';
      r += c(ctx_1.locationId, ctx_1);
      r += '" />\n\n        <ul>\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Date</div>\n                <div class="item-input-wrap">\n                  <input type="date" name="breakDate" value="';
      r += c(ctx_1.breakDate, ctx_1);
      r += '">\n                </div>\n              </div>\n            </div>\n          </li>\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Time</div>\n                <div class="item-input-wrap">\n                  <select name="startTime">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.times, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Length</div>\n                <div class="item-input-wrap">\n                  <select name="length">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.lengths, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                  \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Staff</div>\n                <div class="item-input-wrap">\n                  <select name="staffid">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.allStaff, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.id, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                    \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Type</div>\n                <div class="item-input-wrap">\n                  <select name="breaktype">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.breakTypes, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.id, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                    \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Description</div>\n                <div class="item-input-wrap">\n                  <textarea name="description" rows="3">';
      r += c(ctx_1.description, ctx_1);
      r += '</textarea>\n                </div>\n              </div>\n            </div>\n          </li>\n\n\n        </ul>\n      </form>      \n\n      <div class="block block-strong row">\n        <div class="col"><a class="button button-raised button-fill save" @click="save" href="#">Save</a></div>\n        <div class="col"><a class="button button-raised button-outline" @click="back" href="#">Cancel</a></div>\n      </div>\n\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      function getService(allServices, serviceId) {
  serviceId = parseInt(serviceId);

  for (var i in allServices) {
    for (var x in allServices[i].services) {
      if (allServices[i].services[x].serviceId == serviceId) return allServices[i].services[x];
    }
  }

  return null;
}

export default {
  //todo: when service changes change the default length, processing time and resource. hide processing if zero.
  // Component Data
  data() {
    var self = this;
    var token = JSON.parse(localStorage.getItem('token'));
    var currentAppointment = self.$app.data.currentAppointment;

    if (currentAppointment && currentAppointment.staffId == "undefined") {
      currentAppointment.staffId = null;
    }

    var id = parseInt(self.$route.params.id);
    var increment = currentAppointment.interval;

    if (id) {
      var service = currentAppointment.services.filter(x => x.id == id)[0];
      service.times = getTimes(increment, service.startTime);
      service.lengths = getLengths(increment, service.defaultLength);
      service.processing = getLengths(increment, service.processingLength, true);
      service.resources = getResources(currentAppointment.allServices, service.serviceId, service.resourceId), service.allServices = getSelectedServiceList(currentAppointment.allServices, service.serviceId, service.staffId);
      service.allStaff = getSelectedStaffList(currentAppointment.allStaff, service.staffId);
      service.showProcessing = getService(currentAppointment.allServices, service.serviceId).processingValue > 0;
      service.showResources = getResources(currentAppointment.allServices, service.serviceId).length > 0;
      return service;
    } // If there is already a service find the start time for the next one


    if (currentAppointment.services.length) {
      currentAppointment.services.sort((a, b) => a.startTime > b.startTime ? 1 : -1);
      var service = currentAppointment.services[currentAppointment.services.length - 1];
      var startTime = moment(service.startTime).add(service.defaultLength + (service.processingLength || 0), 'minute').format("HH:mm:ss");
      return {
        id: -Date.now(),
        allServices: getSelectedServiceList(currentAppointment.allServices, 0, service.staffId),
        allStaff: getSelectedStaffList(currentAppointment.allStaff, service.staffId),
        startTime: startTime,
        staffId: currentAppointment.staffId,
        times: getTimes(increment, currentAppointment.appointmentDate + 'T' + startTime),
        lengths: getLengths(increment, increment),
        processing: getLengths(increment, 0, true),
        resources: [],
        showProcessing: false,
        showResources: false
      };
    } // first service


    return {
      id: -Date.now(),
      allServices: getSelectedServiceList(currentAppointment.allServices, 0, currentAppointment.staffId || token.staffId),
      allStaff: getSelectedStaffList(currentAppointment.allStaff, currentAppointment.staffId || token.staffId),
      startTime: currentAppointment.appointmentTime,
      staffId: currentAppointment.staffId || token.staffId,
      times: getTimes(increment, currentAppointment.appointmentDate + 'T' + currentAppointment.appointmentTime),
      lengths: getLengths(increment, 15),
      processing: getLengths(increment, 0, true),
      resources: [],
      showProcessing: false,
      showResources: false
    };
  },

  // Component Methods
  methods: {
    changeStaff: function () {},
    changeService: function () {
      var self = this;
      var id = self.$$("[name=service]").val();
      var currentAppointment = self.$app.data.currentAppointment;
      var service = getService(currentAppointment.allServices, id);
      self.$$('[name=length]').val(service.lengthValue);

      if (service.processingValue > 0) {
        self.$$('.processing-time-container').show();
        self.$$('[name=processingTime]').val(service.processingValue);
      } else {
        self.$$('.processing-time-container').hide();
        self.$$('[name=processingTime]').val('');
      }

      var resources = getResources(currentAppointment.allServices, id);
      self.$$('[name=resourceId]').val('');

      if (resources.length) {
        self.$$('.resources-container').show();
        var html = '';

        for (var i in resources) {
          html += `<option value='${resources[i].value}'>${resources[i].name}</option>`;
        }

        self.$$('[name=resourceId]').html(html);
        self.$$('[name=resourceId]').val(resources[0].id);
      } else {
        self.$$('.resources-container').hide();
      }
    },
    backService: function () {
      var self = this;
      self.$app.views.main.router.navigate("/appointment/back/" + self.$app.data.currentAppointment.id);
    },
    saveService: function () {
      var self = this;
      var formData = self.$app.form.convertToData("#appointment-service-form");
      if (!self.$app.input.validateInputs('#appointment-service-form')) return;
      var currentAppointment = self.$app.data.currentAppointment;
      var id = parseInt(self.$route.params.id);
      var service = {};

      if (id) {
        service = currentAppointment.services.filter(x => x.id == id)[0];
      }

      var time = currentAppointment.appointmentDate + 'T' + formData.serviceTime;
      service.id = parseInt(formData.id);
      service.defaultLength = parseInt(formData.length);
      service.length = formData.length + " mins";
      service.processingLength = parseInt(formData.processingTime || 0);
      service.resourceId = parseInt(formData.resourceId);
      service.resourceName = getResourceName(currentAppointment.allServices, service.serviceId, service.resourceId);
      service.serviceId = parseInt(formData.service);
      service.serviceName = getServiceName(currentAppointment.allServices, service.serviceId);
      service.staffId = parseInt(formData.staff);
      service.staffName = getStaffName(currentAppointment.allStaff, service.staffId);
      service.startTime = time;
      service.startTimeDescription = moment(time).format("LT");

      if (id) {
        currentAppointment.services.filter(x => x.id == id)[0] = service;
      } else {
        currentAppointment.services.push(service);
      }

      currentAppointment.services.sort((a, b) => {
        return a.startTime.localeCompare(b.startTime);
      });
      self.$app.views.main.router.navigate("/appointment/back/" + currentAppointment.id);
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '084415261b',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n    <!-- Top Navbar -->\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n        <div class="left">\n          <a href="#" class="link icon-only panel-open" data-panel="left">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n        </div>        \n        <div class="title center">Appointment Service</div>\n        <div class="right">\n          <a href="#" class="link" @click="backService">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md">Back</span>\n          </a>\n        </div>\n      </div>\n    </div>\n    <!-- Scrollable page content-->\n    <div class="page-content">\n\n      <form class="list" id="appointment-service-form">\n\n        <input type="hidden" name="id" value="';
      r += c(ctx_1.id, ctx_1);
      r += '" />\n\n        <ul>\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Service</div>\n                <div class="item-input-wrap">\n                  <select name="service" @change="changeService" required validate data-error-message="Please select a service">\n                    <option></option>\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.allServices, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <optgroup label="';
          r += c(ctx_2.category, ctx_2);
          r += '">\n                    ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.services, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n                    <option value="';
              r += c(ctx_3.serviceId, ctx_3);
              r += '" ';
              r += Template7Helpers.js_if.call(ctx_3, "this.selected", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'selected';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '>';
              r += c(ctx_3.serviceName, ctx_3);
              r += '</option>\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n                    </optgroup>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Staff</div>\n                <div class="item-input-wrap">\n                  <select name="staff" @change="changeStaff">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.allStaff, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.id, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                    \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Start Time</div>\n                <div class="item-input-wrap">\n                  <select name="serviceTime">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.times, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Service Length</div>\n                <div class="item-input-wrap">\n                  <select name="length">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.lengths, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                  \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n\n          <li class="processing-time-container" ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.showProcessing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'style="display:none"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Processing Time</div>\n                <div class="item-input-wrap">\n                  <select name="processingTime">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.processing, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                    \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>  \n\n          <li class="resources-container" ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.showResources, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'style="display:none"';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Resource</div>\n                <div class="item-input-wrap">\n                  <select name="resourceId">\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.resources, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                    \n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>  \n        \n        </ul>\n\n\n      </form>      \n\n      <div class="block block-strong row">\n        <div class="col"><a class="button button-raised button-fill save" @click="saveService" href="#">Save</a></div>\n        <div class="col"><a class="button button-raised button-outline" @click="backService" href="#">Cancel</a></div>\n      </div>\n\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    
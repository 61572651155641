
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Component Data
  async data() {
    var self = this;
    var token = JSON.parse(localStorage.getItem('token'));
    self.$app.request.setup({
      headers: {
        'APIKey': token.apiKey,
        'StaffId': token.staffId
      }
    });
    self.$app.preloader.show();
    var id = self.$route.params.id;
    var data = await self.$app.request.promise.getJSON('https://app.youreontime.com/1/api/dashboard', {
      locationId: token.locationId,
      staffId: token.staffId
    }).then(res => {
      self.$app.preloader.hide();
      return res.data;
    }).catch(res => {
      self.$app.preloader.hide();

      if (res.status == 401) {
        localStorage.removeItem('token');
      }

      return {
        security: {}
      };
    });
    data.staffName = token.staffName;
    data.security = token.security;
    return data;
  },

  // Component Methods
  methods: {
    edit: function () {
      var self = this;
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
      var token = JSON.parse(localStorage.getItem('token'));

      if (!token) {
        self.$app.views.main.router.navigate("/login");
        return;
      }

      document.addEventListener("updatefound", function () {
        var notificationCallbackOnClose = self.$app.notification.create({
          title: 'Update Available',
          titleRightText: 'now',
          subtitle: 'Click this message to install update.',
          closeOnClick: true,
          on: {
            close: function () {
              forceSWupdate(22);
            }
          }
        });
      });
      var $ptrContent = self.$$('.ptr-content');
      $ptrContent.on('ptr:refresh', function (e) {
        self.$app.views.main.router.navigate("/", {
          reloadCurrent: true
        });
        self.$app.ptr.done();
      });
    }
  },
  id: '6a4f4a767f',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n\n    <!-- Top Navbar -->\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n        <div class="left">\n          <a href="#" class="link icon-only panel-open" data-panel="left">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n          <div class="title center">Dashboard</div>\n        </div>       \n      </div>\n    </div>\n\n\n    <!-- Scrollable page content-->\n    <div class="page-content ptr-content">\n\n        <div class="ptr-preloader">\n          <div class="preloader"></div>\n          <div class="ptr-arrow"></div>\n        </div>\n\n        <div class="block-title block-title-medium">\n          Welcome ';
      r += c(ctx_1.staffName, ctx_1);
      r += '.\n        </div>\n\n        <div class="list inset">\n          <ul>\n            <li>\n              <div class="item-inner">\n                <div class="item-title">Appointments today</div>\n                <div class="item-after">';
      r += c(ctx_1.appointmentsToday, ctx_1);
      r += '</div>\n              </div>              \n            </li>\n            <li>\n              <div class="item-inner">\n                <div class="item-title">% of time booked</div>\n                <div class="item-after">';
      r += c(ctx_1.timeBooked, ctx_1);
      r += '</div>\n              </div>              \n            </li>\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.showStaffDashboard, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showCommissions, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <li>\n              <div class="item-inner">\n                <div class="item-title">Commission earned</div>\n                <div class="item-after">';
              r += c(ctx_3.commissionEarned, ctx_3);
              r += '</div>\n              </div>              \n            </li>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.showTips, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.tipsEarned, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\n            <li>\n              <div class="item-inner">\n                <div class="item-title">Tips earned</div>\n                <div class="item-after">';
                  r += c(ctx_4.tipsEarned, ctx_4);
                  r += '</div>\n              </div>              \n            </li>\n            ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.projectedRevenue, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <li>\n              <div class="item-inner">\n                <div class="item-title">Projected revenue</div>\n                <div class="item-after">';
              r += c(ctx_3.projectedRevenue, ctx_3);
              r += '</div>\n              </div>              \n            </li>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.actualRevenue, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\n            <li>\n              <div class="item-inner">\n                <div class="item-title">Actual revenue</div>\n                <div class="item-after">';
              r += c(ctx_3.actualRevenue, ctx_3);
              r += '</div>\n              </div>              \n            </li>\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </ul>\n        </div>        \n\n\n\n      <div class="block-title">Appointments</div>\n\n      <div class="list media-list">\n        <ul>\n          ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.appointments, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n          <li>\n            <a ';
          r += Template7Helpers.if.call(ctx_2, ctx_1.security.canEditAppointment, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'href="/appointment/edit/';
              r += c(ctx_3.id, ctx_3);
              r += '"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' class="item-link item-content link">\n              <div class="item-inner">\n                <div class="item-title">';
          r += c(ctx_2.startTime, ctx_2);
          r += ', ';
          r += c(ctx_2.description, ctx_2);
          r += '</div>\n              <div class="item-footer">';
          r += c(ctx_2.length, ctx_2);
          r += ' <span class="badge" style="color: black; background-color: ';
          r += c(ctx_2.statusColour, ctx_2);
          r += '">';
          r += c(ctx_2.status, ctx_2);
          r += '</span></div>\n              </div>\n            </a>\n          </li>\n          ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n          <li>\n            <div class="item-content">\n              <div class="item-inner" style="text-align:center">\n                <img width="100px" src="/static/icons/emptylist.svg" />\n                <div class="item-title">No current appointments</div>\n              </div>              \n            </div>             \n          </li>\n          ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n        </ul>\n      </div>       \n\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    
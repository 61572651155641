export default {
  // Component Data
  data: function () {
    var version = localStorage.getItem("current-version") || 1;
    return {
      version: version,
      businessCode: "" //localStorage.getItem("businesscode")

    };
  },
  // Component Methods
  methods: {
    update: function () {
      var version = localStorage.getItem("current-version") || 1;
      forceSWupdate(version++);
    },
    login: function () {
      var self = this;
      var formData = self.$app.form.convertToData("#login-form");
      self.$app.preloader.show();
      self.$app.request.postJSON('https://appserv1.youreontime.com/1/api/auth', formData, function (data) {
        self.$app.preloader.hide();

        if (!data.complete) {
          self.$app.dialog.alert(data.error);
          return;
        }

        self.$$('.change-location').removeClass('can-change-location');

        if (data.security.canChangeLocation) {
          self.$$('.change-location').addClass('can-change-location');
        }

        localStorage.setItem('token', JSON.stringify(data));
        self.$app.views.main.router.navigate("/dashboard");

        if (localStorage.getItem("current-version") != data.appVersion) {
          //self.$app.dialog.alert("Updating app");
          forceSWupdate(data.appVersion);
        }
      });
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {//console.log('pageInit', page);
    }
  },
  id: '96ea131b97',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar no-toolbar no-swipeback login-screen-page"><div class="page-content login-screen-content"><div class=login-screen-title><img width=70% src=/static/logo-white.svg></div><form id=login-form><div class=list><ul><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Username</div><div class=item-input-wrap><input type=text name=username placeholder="Your username"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Password</div><div class=item-input-wrap><input type=password name=password placeholder="Your password"></div></div></div></li><li><div class="item-content item-input"><div class=item-inner><div class="item-title item-label">Business Code</div><div class=item-input-wrap><input type=text name=businesscode placeholder="Your businesscode"></div></div></div></li><li><div class=row><div class=col-50 style="padding-left: 15px"><a class=external href="https://app.youreontime.com/Account/Login?full=true" style="color:white; float:left">Go to full application</a></div><div class=col-50 style="padding-right: 15px; text-align: right;"><a href=# @click=update style=color:white>Update App</a></div></div></li></ul></div><div class=list style="padding: 15px"><a href=# @click=login class="button button-fill">Sign In</a></div></form></div></div>';
      return r;
    }(this);
  },

  style: `

.login-screen-page {
    background: hsl(179, 22%, 59%);
}

.login-screen-content {
  background: #80adac; 
}

.login-screen-page .button.button-fill {
  background-color: hsl(179,22%,24%)!important;
}

.login-screen-page .item-title.item-label {
  color: white;
}

.md .item-input:not(.item-input-outline) .item-input-wrap:after, 
.md .input:not(.input-outline):after {
    background-color: white;
}

`,
  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  // Component Data
  async data() {
    var self = this;
    var increment = self.$app.data.currentAppointment.interval;
    var data = self.$app.data.currentAppointment;
    data.times = getTimes(increment, data.appointmentDate + 'T' + data.appointmentTime);
    data.services.sort((a, b) => a.startTime > b.startTime ? 1 : -1);
    data.canEdit = data.status != 'Cancelled' && data.status != 'Complete';
    console.log(data);
    return data;
  },

  // Component Methods
  methods: {
    changeDate: function () {
      var self = this;
      var currentAppointment = self.$app.data.currentAppointment;
      var formData = self.$app.form.convertToData("#appointment-form");
      currentAppointment.appointmentDate = formData.appointmentDate;
    },
    changeTime: function () {
      var self = this;
      var currentAppointment = self.$app.data.currentAppointment;
      var formData = self.$app.form.convertToData("#appointment-form");
      var originalTime = `${currentAppointment.appointmentDate}T${currentAppointment.appointmentTime}`;
      var newTime = `${currentAppointment.appointmentDate}T${formData.appointmentTime}`;
      var diff = moment(newTime).diff(moment(originalTime), "minutes");
      currentAppointment.appointmentTime = formData.appointmentTime;

      for (var i in currentAppointment.services) {
        var time = moment(currentAppointment.services[i].startTime).add(diff, 'minutes').format("YYYY-MM-DDTHH:mm:ss");
        currentAppointment.services[i].startTime = time;
        currentAppointment.services[i].startTimeDescription = moment(time).format("LT");
      }
    },
    back: function () {
      var self = this;
      self.$app.views.main.router.navigate("/appointments"); //todo: Should return to specific date
    },
    save: function () {
      //todo: Resources
      var self = this;
      var formData = self.$app.form.convertToData("#appointment-form");
      var data = self.$app.data.currentAppointment;
      data.services.sort((a, b) => a.startTime > b.startTime ? 1 : -1);
      self.$app.preloader.show();
      data.times = null;
      data.allServices = null;
      self.$app.request.postJSON('https://api.youreontime.com/1/api/appointments/save', data, function (data) {
        self.$app.preloader.hide();

        if (data.warning) {
          var notification = self.$app.notification.create({
            icon: '<i class="icon material-icons">error</i>',
            title: 'Warning',
            text: data.warning + `
              <div class="block">
                <div class="row">
                  <button class="col button button-fill continue-save-btn">Continue</button>
                  <button class="col button button-outline cancel-save-btn">Cancel</button>
                </div>
              </div>`
          }).open();
          self.$$('.continue-save-btn').on('click', function () {
            self.$app.data.currentAppointment.ignoreWarnings = true;
            notification.close();
            self.save();
          });
          self.$$('.cancel-save-btn').on('click', function () {
            notification.close();
          });
          return;
        }

        if (data.error) {
          self.$app.notification.create({
            icon: '<i class="icon material-icons">error</i>',
            title: 'Error',
            text: data.error,
            closeTimeout: 5000,
            closeButton: true
          }).open();
          return;
        }

        self.$app.views.main.router.navigate("/appointments"); //todo: Should return to specific date
      });
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
    }
  },
  id: '1a3d32b815',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '\n  <div class="page" data-name="home">\n    <!-- Top Navbar -->\n    <div class="navbar">\n      <div class="navbar-bg"></div>\n      <div class="navbar-inner">\n        <div class="left">\n          <a href="#" class="link icon-only panel-open" data-panel="left">\n            <i class="icon f7-icons if-not-md">menu</i>\n            <i class="icon material-icons if-md">menu</i>\n          </a>\n        </div>        \n        <div class="title center">Appointment</div>\n        <div class="right">\n          <a href="#" class="link" @click="back">\n            <i class="icon icon-back"></i>\n            <span class="if-not-md">Back</span>\n          </a>\n        </div>\n      </div>\n    </div>\n    <!-- Scrollable page content-->\n    <div class="page-content">\n\n      <form class="list" id="appointment-form">\n\n        <input type="hidden" name="id" value="';
      r += c(ctx_1.id, ctx_1);
      r += '" />\n        <input type="hidden" name="staffId" value="';
      r += c(ctx_1.staffId, ctx_1);
      r += '" />\n        <input type="hidden" name="ignoreWarnings" value="false" />\n\n        <ul>\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Date</div>\n                <div class="item-input-wrap">\n                  <input type="date" name="appointmentDate" @change="changeDate" value="';
      r += Template7Helpers.if.call(ctx_1, ctx_1.appointmentDate, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += c(ctx_2.appointmentDate, ctx_2);
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.canEdit, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>\n                </div>\n              </div>\n            </div>\n          </li>\n          <li>\n            <div class="item-content item-input">\n              <div class="item-inner">\n                <div class="item-title item-label">Time</div>\n                <div class="item-input-wrap">\n                  <select name="appointmentTime" @change="changeTime" ';
      r += Template7Helpers.unless.call(ctx_1, ctx_1.canEdit, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += 'disabled';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '>\n                    ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.times, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n                    <option value="';
          r += c(ctx_2.value, ctx_2);
          r += '" ';
          r += Template7Helpers.js_if.call(ctx_2, "this.selected", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'selected';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '>';
          r += c(ctx_2.name, ctx_2);
          r += '</option>\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n                  </select>\n                </div>\n              </div>\n            </div>\n          </li>\n        </ul>\n\n        <div class="list media-list">\n          <ul>\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.clientId, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <li>\n              <a ';
          r += Template7Helpers.if.call(ctx_2, ctx_1.canEdit, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'href="/appointment/clients"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' class="item-link item-content link">\n                <div class="item-inner">\n                  <div class="item-title-row">\n                    <div class="item-title">';
          r += c(ctx_2.clientName, ctx_2);
          r += '</div>\n                  </div>\n                  <div class="item-footer">';
          r += c(ctx_2.clientPhone, ctx_2);
          r += ' ';
          r += c(ctx_2.clientEmail, ctx_2);
          r += '</div>\n                </div>\n              </a>\n            </li>\n            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <li><a href="/appointment/clients" class="item-link item-content link">\n              <div class="item-title-row"><div class="item-title">Select Client</div></div>              \n            </a></li>\n            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n          </ul>\n        </div>           \n\n        <div class="block-title">Services</div>\n\n        <div class="list media-list">\n          <ul>\n            ';
      r += Template7Helpers.each.call(ctx_1, ctx_1.services, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <li>\n              <a ';
          r += Template7Helpers.if.call(ctx_2, ctx_1.canEdit, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'href="/appointment/serviceedit/';
              r += c(ctx_3.id, ctx_3);
              r += '"';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' class="item-link item-content link">\n                <div class="item-inner">\n                  <div class="item-header">';
          r += c(ctx_2.startTimeDescription, ctx_2);
          r += '</div>\n                  <div class="item-title"><div class="item-title-row">';
          r += c(ctx_2.serviceName, ctx_2);
          r += '</div></div>\n                  <div class="item-footer">';
          r += c(ctx_2.length, ctx_2);
          r += ' with ';
          r += c(ctx_2.staffName, ctx_2);
          r += Template7Helpers.if.call(ctx_2, ctx_2.resourceName, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<br>';
              r += c(ctx_3.resourceName, ctx_3);
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\n                </div>\n              </a>\n            </li>\n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.canEdit, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n            <li><a href="/appointment/serviceedit/0" class="item-link item-content link">\n              <div class="item-title-row"><div class="item-title">Add A Service</div></div>              \n            </a>\n            </li>  \n            ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '          \n          </ul>\n        </div>         \n\n\n      </form>      \n\n      <div class="block block-strong row">\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.canEdit, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\n        <div class="col"><a class="button button-raised button-fill save" @click="save" href="#">Save</a></div>\n        <div class="col"><a class="button button-raised button-outline" @click="back" href="#">Cancel</a></div>\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\n        <div class="col"><a class="button button-raised button-fill" @click="back" href="#">Close</a></div>\n        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\n      </div>\n\n    </div>\n  </div>\n';
      return r;
    }(this);
  },

  styleScoped: false
};
    
export default {
  // Component Data
  data() {
    return {};
  },

  // Component Methods
  methods: {
    select: function () {
      var self = this;
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
      var today = self.$app.data.selectedDate || new Date();
      var calendarDisabled = self.$app.calendar.create({
        containerEl: '#calendar-container',
        value: [today],
        currentMonth: today.getMonth(),
        currentYear: today.getFullYear(),
        on: {
          dayClick: function (calendar, dayEl, year, month, day) {
            //self.$app.dialog.alert(value);
            if (!self.$app.data) self.$app.data = {}; //console.log(value);

            self.$app.data.selectedDate = new Date(year, month, day);
            self.$app.views.main.router.navigate("/appointments/");
          }
        }
      });
      calendarDisabled.setYearMonth(today.getFullYear(), today.getMonth(), 1);
    }
  },
  id: '601c82a6ea',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=about><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class=title>Select Date</div><div class=right><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div></div></div><div class=page-content><div id=calendar-container></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      async function doSearch(self, searchText) {
  var data = JSON.parse(localStorage.getItem('token'));
  self.$app.request.setup({
    headers: {
      'APIKey': data.apiKey,
      'StaffId': data.staffId
    }
  });
  self.$app.preloader.show();
  var data = await self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/clients', {
    locationId: data.locationId,
    search: searchText
  }).then(res => {
    self.$app.preloader.hide();
    return res.data;
  });
  return data;
}

function doSearch2(self, searchText, cb) {
  var data = JSON.parse(localStorage.getItem('token'));
  self.$app.request.setup({
    headers: {
      'APIKey': data.apiKey,
      'StaffId': data.staffId
    }
  });
  self.$app.preloader.show();
  var data = self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/clients', {
    locationId: data.locationId,
    search: searchText
  }).then(res => {
    self.$app.preloader.hide();
    cb(res.data);
  });
  return data;
}

export default {
  // Component Data
  async data() {
    var self = this;
    var data = await doSearch(self);
    return {
      items: data
    };
  },

  // Component Methods
  methods: {
    select: function (id) {
      var self = this;
      var data = JSON.parse(localStorage.getItem('token'));
      self.$app.request.setup({
        headers: {
          'APIKey': data.apiKey,
          'StaffId': data.staffId
        }
      });
      self.$app.preloader.show();
      var data = self.$app.request.promise.getJSON('https://api.youreontime.com/1/api/client/' + id, {
        locationId: data.locationId
      }).then(res => {
        self.$app.preloader.hide(); //console.log(res.data);

        self.$app.data.currentAppointment.clientEmail = res.data.emailAddress;
        self.$app.data.currentAppointment.clientId = res.data.id;
        self.$app.data.currentAppointment.clientName = res.data.name;
        self.$app.data.currentAppointment.clientPhone = res.data.mobilePhone;
        self.$app.views.main.router.navigate("/appointment/back/" + self.$app.data.currentAppointment.id);
      });
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
      var searchTimer;
      var searchbar = self.$app.searchbar.create({
        el: '.searchbar',
        customSearch: true,
        on: {
          async search(sb, query, previousQuery) {
            if (searchTimer) clearTimeout(searchTimer);
            searchTimer = setTimeout(() => {
              doSearch2(self, query, data => {
                self.$setState({
                  items: data
                });
              });
            }, 1000);
          }

        }
      });
    }
  },
  id: '90801f5ed4',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center">Clients</div><div class=right><a href=# class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Back</span></a></div><div class=subnavbar><!-- Searchbar with auto init --><form class=searchbar><div class=searchbar-inner><div class=searchbar-input-wrap><input type=search placeholder="Search by name or phone number"> <i class=searchbar-icon></i> <span class=input-clear-button></span></div><span class="searchbar-disable-button if-not-aurora">Cancel</span></div></form></div></div></div><!-- Floating Action Button --><div class="fab fab-right-bottom"><a href=/appointment/clientadd><i class="icon f7-icons">plus</i></a></div><!-- Scrollable page content--><div class=page-content><!-- Searchbar backdrop --><div class=searchbar-backdrop></div><!-- search target list --><div class="list media-list"><ul class="">';
      r += Template7Helpers.each.call(ctx_1, ctx_1.items, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a href=# @click="select(\'';
          r += c(ctx_2.id, ctx_2);
          r += '\')" data-id=';
          r += c(ctx_2.id, ctx_2);
          r += ' class="item-link item-content"><div class=item-media><div class=profile-image>';
          r += c(ctx_2.initial, ctx_2);
          r += ' <img src=https://api.youreontime.com/1/api/client/photo/';
          r += c(ctx_2.privateId, ctx_2);
          r += ' width=44></div></div><div class=item-inner><div class=item-title-row><div class=item-title>';
          r += c(ctx_2.name, ctx_2);
          r += '</div></div><div class=item-subtitle>';
          r += c(ctx_2.phone, ctx_2);
          r += ' ';
          r += c(ctx_2.emailAddress, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div><!-- Nothing found message --><div class="block searchbar-not-found"><div class=block-inner>Nothing found</div></div></div></div>';
      return r;
    }(this);
  },

  style: `
  .profile-image {
    background-color: #cfebea;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;    
  }

  .profile-image img {
    position: absolute;
    top: 15px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
  }
`,
  styleScoped: false
};
    
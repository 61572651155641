var calendar;

function loadData(self, cb) {
  // Load data
  var token = JSON.parse(localStorage.getItem('token'));
  self.$app.request.setup({
    headers: {
      'APIKey': token.apiKey,
      'StaffId': token.staffId
    }
  });
  var preloader = self.$app.preloader;
  preloader.show();
  self.$app.request.json('https://api.youreontime.com/1/api/appointmentsrange', {
    date: (self.$app.data.selectedDate || new Date()).getTime(),
    enddate: (self.$app.data.selectedDate || new Date()).getTime() + 1000 * 60 * 60 * 24 * 7,
    staffId: token.staffId,
    locationId: token.locationId
  }, function (res) {
    preloader.hide();
    cb(null, res);
  }, function (err) {
    cb('Error getting appointments ' + err);
  });
}

function buildStaffMenu(self, staffs) {
  var menu = "";

  for (var i in staffs) {
    var staff = staffs[i]; //console.log(staff);

    menu += `
      <li>
        <a href="/staff/change/${staff.id}" class="item-link item-content staff-menu-item panel-close">
          <div class="item-media">
            <div class="profile-image">
              ${staff.initial}
              <img src="https://api.youreontime.com/1/api/staff/photo/${staff.id}" width="44" style="border-radius:50%"/>
            </div>
          </div>
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title">${staff.name}</div>
            </div>
          </div>
        </a>
      </li>
    `;
  }

  menu = `<div class="list media-list"><ul class="">${menu}</ul></div>`;
  self.$$('.staff-menu-list').html(menu);
}

function setUpCalendar(self, res, date, cb) {
  /* Ref
  https://fullcalendar.io/docs/businessHours
  */
  console.log(res);
  var currentDay = res.openHours[moment(date).day()];
  var day = moment(date).date();
  var month = moment(date).month() + 1;
  var year = moment(date).year();
  var firstHour = currentDay.openHour;
  var lastHour = currentDay.closeHour;
  var todaysAppointments = res.data.appointments.filter(x => {
    return x.day == day && x.month == month && x.year == year;
  });
  var minHour = Math.min(...todaysAppointments.map(item => item.startHour));
  var maxHour = Math.max(...todaysAppointments.map(item => item.endHour));
  if (minHour && minHour < firstHour) firstHour = minHour;
  if (maxHour && maxHour > lastHour) lastHour = maxHour;
  console.log(maxHour);
  if (!firstHour) firstHour = 1;
  if (!lastHour || lastHour == 24) lastHour = 23;
  console.log(firstHour);
  console.log(lastHour);
  var step = moment(new Date(0, 0, 0, 0, res.step, 0)).format('HH:mm:ss');
  var minTime = moment(new Date(0, 0, 0, firstHour, 0, 0)).format('HH:mm:ss');
  var maxTime = moment(new Date(0, 0, 0, lastHour, 0, 0)).format('HH:mm:ss');
  var token = JSON.parse(localStorage.getItem('token'));
  var currentStaff = token.staffId;
  var currentView = self.$app.data.selectedView || 'timeGridThreeDay'; // resourceTimeGridDay, resourceTimeGridThreeDay, resourceTimeGridWeek
  // todo: Create a new view for Managers, or people who can see all staff, and it only shows one day but multiple resources - resourceTimeGridDay
  // https://fullcalendar.io/docs/vertical-resource-standard-demo

  self.$app.data.allStaff = res.staff;
  buildStaffMenu(self, res.staff);
  self.$app.$('.appointment-header').hide();
  self.$app.$('.add-appointment').hide();

  if (token.security.canAddAppointment) {
    self.$app.$('.add-appointment').show();
  }

  var resources = res.data.resources.filter(x => {
    return x.id == currentStaff;
  }).map(x => {
    return {
      id: x.id,
      title: x.name
    };
  });

  if (token.security.canChangeStaff && res.data.resources.length > 1) {
    resources = res.data.resources.map(x => {
      return {
        id: x.id,
        title: x.name
      };
    });
    currentView = "resourceTimeGridDay";
    self.$app.$('.appointment-header').hide();
  } else {
    self.$app.$('.appointment-header').show();
  }

  if (resources.length) {
    var startHour = Math.min(...res.data.resources.map(o => o.startHour));
    var endHour = Math.max(...res.data.resources.map(o => o.endHour));
    if (startHour < firstHour) minTime = moment(new Date(0, 0, 0, startHour, 0, 0)).format('HH:mm:ss');
    if (endHour > lastHour && endHour != 24) maxTime = moment(new Date(0, 0, 0, endHour, 0, 0)).format('HH:mm:ss');
  }

  var options = {
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    initialView: currentView,
    initialDate: date,
    height: 'auto',
    locale: token.locale,
    allDaySlot: false,
    slotDuration: step,
    slotMinTime: minTime,
    slotMaxTime: maxTime,
    headerToolbar: false,
    displayEventTime: false,
    resources: resources,
    datesAboveResources: true,
    nowIndicator: true,
    views: {
      timeGridThreeDay: {
        type: 'timeGrid',
        duration: {
          days: 3
        },
        buttonText: '3 days',
        titleFormat: {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      },
      timeGridSevenDay: {
        type: 'timeGrid',
        duration: {
          days: 7
        },
        buttonText: '7 days',
        titleFormat: {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      }
    },
    eventClick: function (info) {
      var app = self.$app;
      var buttons = [];

      if (token.security.canEditAppointment) {
        buttons.push({
          text: "Edit Appointment",
          onClick: function () {
            app.views.main.router.navigate("/appointment/edit/" + info.event.extendedProps.appointment_id);
          }
        });
      }

      if (token.security.canCancelAppointment) {
        buttons.push({
          text: "Cancel Appointment",
          onClick: function () {
            var id = info.event.extendedProps.appointment_id;
            app.dialog.confirm('Cancel this appointment?', 'Cancel', function () {
              self.$app.preloader.show();
              self.$app.request.postJSON('https://api.youreontime.com/1/api/appointment/cancel/' + id, {}, function (data) {
                self.$app.preloader.hide();
                var date = moment(self.$app.data.selectedDate || new Date()).format('YYYY-MM-DD');
                loadData(self, (err, data) => {
                  if (data) setUpCalendar(self, data, date, () => {});
                });
              });
            });
          }
        });
      }

      buttons.push({
        text: "Go to client",
        onClick: function () {
          app.views.main.router.navigate("/client/" + info.event.extendedProps.client_id);
        }
      });
      buttons.push({
        text: 'Close',
        color: 'red'
      });
      var ac = app.actions.create({
        buttons: buttons
      });
      ac.open(); //self.$app.views.main.router.navigate("/appointment/edit/" + info.event.extendedProps.appointment_id); 
    },
    dateClick: function (info, e) {
      var app = self.$app;

      if (info.resource) {
        var staffid = info.resource.id;
      }

      var buttons = [];

      if (token.security.canAddAppointment) {
        buttons.push({
          text: "Add Appointment",
          onClick: function () {
            self.$app.views.main.router.navigate("/appointment/edit/0?date=" + info.date.getTime() + '&staffid=' + staffid);
          }
        });
      }

      if (token.security.canAddBreak) {
        buttons.push({
          text: "Add Break",
          onClick: function () {
            self.$app.views.main.router.navigate("/break/edit/0?date=" + info.date.getTime() + '&staffid=' + staffid);
          }
        });
      }

      buttons.push({
        text: 'Close',
        color: 'red'
      });
      var ac = app.actions.create({
        buttons: buttons
      });
      ac.open();
    },
    events: function (info, successCallback, failureCallback) {
      //console.log('load events ' + info.startStr + ' to ' + info.endStr);
      var data = res.data;
      var statuses = res.statuses;
      var categories = res.categories;
      var colorType = res.colorType;
      var events = [];

      for (var i in res.closedDays) {
        var a = res.closedDays[i];
        var event = {
          title: 'Closed',
          start: new Date(a.year, a.month - 1, a.day, 0, 0, 0),
          end: new Date(a.year, a.month - 1, a.day, 23, 0, 0),
          backgroundColor: "#c0c0c0",
          display: 'background',
          textColor: '#000',
          classNames: ['breakcolor']
        };
        events.push(event);
      }

      var resources = data.resources;

      for (var r in data.resources) {
        if (!token.security.canChangeStaff && resources[r].id != currentStaff) continue;
        var breaks = resources[r].breaks;

        for (var i in breaks) {
          var a = breaks[i];
          console.log(a);
          var event = {
            break_id: a.id,
            title: a.description,
            start: new Date(a.year, a.month - 1, a.day, a.startHour, a.startMinute, 0),
            end: new Date(new Date(a.year, a.month - 1, a.day, a.startHour, a.startMinute, 0).getTime() + 1000 * 60 * a.length),
            backgroundColor: "#" + a.color.toString(16).padStart(6, '0'),
            display: 'background',
            textColor: '#000',
            classNames: ['breakcolor'],
            resourceId: resources[r].id
          };
          events.push(event);
        }

        var openCloseBreaks = resources[r].openCloseBreaks;

        for (var i in openCloseBreaks) {
          var a = openCloseBreaks[i];
          var event = {
            break_id: a.id,
            title: a.description || '',
            start: new Date(a.year, a.month - 1, a.day, a.startHour, a.startMinute, 0),
            end: new Date(new Date(a.year, a.month - 1, a.day, a.startHour, a.startMinute, 0).getTime() + 1000 * 60 * a.length),
            backgroundColor: "#c0c0c0",
            display: 'background',
            textColor: '#000',
            classNames: ['breakcolor'],
            resourceId: resources[r].id
          };
          events.push(event);
        }
      }

      for (var i in data.appointments) {
        var a = data.appointments[i]; //console.log(a);

        var colour = statuses.filter(x => x.id == a.status)[0].color;

        if (colorType == 'Category') {
          var category = categories.filter(x => x.id == a.category);

          if (category.length) {
            var colour = category[0].color;
          }
        }

        var backgroundColor = "#" + colour.toString(16).padStart(6, '0');
        var title = `${a.clientName} (${a.clientPhone}) for ${a.service}`;
        if (!a.clientPhone) title = `${a.clientName} for ${a.service}`;
        var event = {
          appointment_id: a.appointmentId,
          client_id: a.clientId,
          title: title,
          start: new Date(a.year, a.month - 1, a.day, a.startHour, a.startMinute, 0),
          end: new Date(a.year, a.month - 1, a.day, a.endHour, a.endMinute, 0),
          backgroundColor: backgroundColor,
          borderColor: '#dedede',
          textColor: '#000',
          resourceId: a.resourceId
        };

        if (token.security.canChangeStaff && res.data.resources.length > 1 || a.resourceId == currentStaff) {
          events.push(event);
        }
      }

      successCallback(events);
      cb();
    }
  };

  if (token.security.canChangeStaff && res.data.resources.length > 1) {
    options.dayMinWidth = 150;
    options.stickyFooterScrollbar = true;
  }

  var calendarEl = document.getElementById("calender-container");
  calendar = new FullCalendar.Calendar(calendarEl, options);
  calendar.render();
}

export default {
  // Component Data
  async data() {
    var self = this;
    var token = JSON.parse(localStorage.getItem('token'));
    var data = {
      staffName: token.staffName,
      staffId: token.staffId,
      selectedDate: moment(self.$app.data.selectedDate || new Date()).format('ddd Do MMM[,] YYYY')
    };
    return data;
  },

  // Component Methods
  methods: {
    edit: function () {
      var self = this;
    }
  },
  // Page Events
  on: {
    pageInit: function (e, page) {
      var self = this;
      var date = moment(self.$app.data.selectedDate || new Date()).format('YYYY-MM-DD');
      loadData(self, (err, data) => {
        if (data) setUpCalendar(self, data, date, () => {});
      });
    }
  },
  id: '39812301eb',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=home><!-- Top Navbar --><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link icon-only panel-open" data-panel=left><i class="icon f7-icons if-not-md">menu</i> <i class="icon material-icons if-md">menu</i></a></div><div class="title center"><a href=/appointmentdate/ >';
      r += c(ctx_1.selectedDate, ctx_1);
      r += ' <i class="icon f7-icons">chevron_down</i></a></div><div class=right><a href=# class="link icon-only panel-open" data-panel=right><i class="icon f7-icons if-not-md">settings</i> <i class="icon material-icons if-md">settings</i></a></div></div></div><!-- Floating Action Button --><div class="fab fab-right-bottom add-appointment" style=display:none><a href=/appointment/edit/0><i class="icon f7-icons">plus</i></a></div><!-- Scrollable page content--><div class=page-content><div class=appointment-header style=display:none><img style="border-radius: 50%" src=https://api.youreontime.com/1/api/staff/photo/';
      r += c(ctx_1.staffId, ctx_1);
      r += ' width=40> <span>';
      r += c(ctx_1.staffName, ctx_1);
      r += '</span></div><div id=calender-container></div></div></div>';
      return r;
    }(this);
  },

  style: `
  .profile-image {
    background-color: #cfebea;
    border-radius: 50%;
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;    
  }

  .profile-image img {
    position: absolute;
    top: 15px;
    height: 44px;
    width: 44px;
    border-radius: 50%;
  }
`,
  styleScoped: false
};